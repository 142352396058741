import React from 'react'
import PropTypes from 'prop-types'
import LogRocket from 'logrocket'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
    if(process.env.GATSBY_APP_ENV !== 'dev') {
      LogRocket.init(process.env.GATSBY_LOGROCKET_APP_ID)
    }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // TODO: Disable in development
    LogRocket.captureException({error, errorInfo})
  }

  render() {
    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
}

export default ErrorBoundary
